import axios from 'axios'
import { storageService } from './storage.service'

const baseUrl = storageService.setApiUrl()

class AuthService{
	apiUrl = baseUrl

	async processLogin (postData) {
		return await axios.post(`${baseUrl}/login`, postData)
	}

	async fetchClient () {
		const apiToken = await storageService.getToken()
		return await axios.get(`${baseUrl}/load-client`, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

	async storeClient (postData) {
		const apiToken = await storageService.getToken()
		return await axios.post(`${baseUrl}/create-client`, postData, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

	async updateClient (postData) {
		const apiToken = await storageService.getToken()
		return await axios.post(`${baseUrl}/update-client`, postData, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

	async deleteClient (postData) {
		const apiToken = await storageService.getToken()
		return await axios.post(`${baseUrl}/delete-client`, postData, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}
}

const authService = new AuthService()
export { authService }