<template>
	<div class="page-container">
		<div class="card">
			<div class="card-body">
				<div style="display: flex; justify-content: center; flex-direction: row;">
					<welcome-icon />
				</div>
				<div class="alert alert-danger alert-dismissable" v-if="showErrorAlert">
					{{ errorMessage }}
				</div>
				<form @submit.prevent="onSubmit" novalidate>
					<div class="form-group mb-2">
						<label>Email</label>
						<input type="email" placeholder="Enter email address" v-model="vv.email.$model" class="form-control">
						<span class="error">{{ vv?.email?.$errors[0]?.$message }}</span>
					</div>
					<div class="form-group mb-3">
						<label>Password</label>
						<input type="password" placeholder="Enter password" v-model="vv.password.$model" class="form-control">
						<span class="error">{{ vv?.password?.$errors[0]?.$message }}</span>
					</div>
					<div class="d-grid gap-2 mb-2">
						<button class="btn btn-primary btn-block" :disabled="isProcessing">
							<span v-if="isProcessing" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
							<span v-if="isProcessing" style="padding-left: 15px !important;">Validating</span>
							<span v-if="!isProcessing">Login</span>
						</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
import { reactive } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import WelcomeIcon from '@/components/Icons/WelcomeIcon'
import { authService } from '@/services/authentication.service'
import { storageService } from '@/services/storage.service'

export default {
	name: 'LandingPage',
	components: {
		WelcomeIcon
	},
	setup() {
		const fform = reactive({
			email: '',
			password: ''
		})
		const rules = {
			email: { required, email },
			password: { required }
		}
		const vv = useVuelidate(rules, fform);
		return {
			vv,
			fform
		}
	},
	data() {
		return {
			isLoading: true,
			isProcessing: false,
			showErrorAlert: false,
			errorMessage: '',
		}
	},
	mounted() {
		this.setupApp()
	},
	methods: {
		setupApp () {
			console.log('System Set')
		},
		onSubmit () {
			this.vv.$touch();
			if (this.vv.$invalid) return;
			this.isProcessing = true
			this.showErrorAlert = false
			this.errorMessage = 'Invalid Login Details'
			const postData = {
				email: this.fform.email,
				password: this.fform.password
			}
			authService.processLogin(postData)
				.then((response) => {
					this.isProcessing = false
					if(parseInt(response.data.message_code) === 200){
						this.isProcessing = false
						storageService.storeLogin(response.data)
						setTimeout(() => {
							this.$router.push('/project-page')
						}, 2000)
					}else{
						this.showErrorAlert = true
						this.errorMessage = 'Invalid Login Details'	
					}
				})
				.catch((error) => {
					console.log(error.message)
					this.isProcessing = false
					this.showErrorAlert = true
					this.errorMessage = 'Error connecting to internet'
				})
		}
	}
};
</script>

<style scoped>
.page-container{
	background-image: url('../image/banner-laptop.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.card{
	width: 350px;
}
</style>
